import * as React from "react"

import Layout from "../components/layout"
import { Helmet } from "react-helmet"
import BannerSection from "../components/whitelabelpage/Banner"
import Whatis from "../components/whitelabelpage/Whatis"
import WhyShould from "../components/whitelabelpage/WhyShould"
import TopFeatures from "../components/whitelabelpage/TopFeatures"
import HowOur from "../components/whitelabelpage/HowOur"
import CoreFeatures from "../components/whitelabelpage/CoreFeatures"
import SecurityofOur from "../components/whitelabelpage/SecurityofOur"
import WhyChoose from "../components/whitelabelpage/WhyChoose"
import Testimonial from "../components/whitelabelpage/Testimonial"
import PortfolioSection from "../components/Landing/Portfolio"
import DevApproach from "../components/whitelabelpage/DevApproach"
import TechStack from "../components/whitelabelpage/TechStack"
import FaqSection from "../components/whitelabelpage/FaqSection"
import UserFriendly from "../components/Cryptoexchange/UserFriendly"
import OurExtensive from "../components/whitelabelpage/OurExtensive"
import BestSelling from "../components/whitelabelpage/BestSelling"
// import UseCase from "../components/whitelabelpage/UseCase"




const WhiteLabel = () => (
  <section className="dev-page">
    <Layout>
      <Helmet>
        <title>White Label Cryptocurrency Exchange Software</title>
        <meta name="keywords" content="White Label Cryptocurrency Exchange Software" />
        <meta name="description" content="Coinsclone is a trusted provider of white-label cryptocurrency exchange software, offering secure, scalable, customizable, and cost-effective trading solution." />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="White Label Cryptocurrency Exchange Software" />
        <meta property="og:description" content="Coinsclone is a trusted provider of white-label cryptocurrency exchange software, offering secure, scalable, customizable, and cost-effective trading solution." />
        <meta property="og:url" content="https://www.coinsclone.com/white-label-crypto-exchange-software/" />
        <meta property="og:image" content="https://coinsclone.mo.cloudinary.net/images/white-label-crypto-exchange-software.png" />
        <meta property="og:image:secure_url" content="https://coinsclone.mo.cloudinary.net/images/white-label-crypto-exchange-software.png" />
        <meta property="og:image:alt" content="white label crypto exchange" />
        <meta property="og:site_name" content="Coinsclone" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:description" content="Coinsclone is a trusted provider of white-label cryptocurrency exchange software, offering secure, scalable, customizable, and cost-effective trading solution." />
        <meta name="twitter:title" content="White Label Cryptocurrency Exchange Software" />
        <meta name="twitter:image" content="https://coinsclone.mo.cloudinary.net/images/white-label-crypto-exchange-software.png" />
        <link rel="canonical" href="https://www.coinsclone.com/white-label-crypto-exchange-software/" />
        <link rel="preload" fetchpriority="high" as="image" href="https://coinsclone.mo.cloudinary.net/images/whitelabel/white-label-cryptocurrency-exchange.webp" type="image/webp" />
      </Helmet>
      <BannerSection />
      <div className="breadcrumb mb-0">
        <div className="container">
          <span><a href="https://www.coinsclone.com/">Home</a> | White Label Cryptocurrency Exchange Software </span>
        </div>
      </div>
      <Whatis />
      <OurExtensive />
      <TopFeatures />
      <CoreFeatures />
      <SecurityofOur />
      <WhyShould />
      <HowOur />
      <BestSelling />
      <WhyChoose />
      <DevApproach />
      <TechStack />
      <Testimonial />
      <PortfolioSection />
      <div className='dot-box'>
        <p>Don’t Wait for More! <span className="d-block">The Best White Label Cryptocurrency Exchange Software Is Just In Front Of You!</span></p>
        <div className='dot-btn'>
        <a href="https://www.coinsclone.com/contact-us/" className="bluebtn">Book a Free Demo!</a>
        </div>
      </div>
      
      {/* <UseCase /> */}
      
      <FaqSection />
      <UserFriendly />
    </Layout>
  </section>
)
  
export default WhiteLabel
